import { MoreVert } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { useState } from "react"
import logo from '../../Assets/logo_with_background.png'
export const WebviewPage = () => {
    const [copied, setCopied] = useState(false)
    const copyText = async () => {
       await navigator.clipboard.writeText(window.location.href)
       setCopied(true)
       setTimeout(() => {
        setCopied(false)
       }, 5000)
    }
    return (
        <Box paddingY={2} textAlign="center">
            <Box component="img" 
            src={logo} sx={{width: '13vw', maxWidth: '250px', minWidth: '150px', paddingY: 2}} />
            <Typography><b>Unsupported Browser</b></Typography>
            <br />
            <Typography alignItems='center'>
                Looks like you're using an unsupported browser <br />
                Copy the url and paste it into any browser 
                <br />(Safari, Chrome, Firefox, Opera ...etc)
                <br />
                <br />
                <Button onClick={copyText} variant="contained">{copied ? "Copied!" : "Click to copy URL"}</Button>
                <br />
                <br />
                or
                <br /><br />
                Click the <MoreVert /> in the menu below
                <br />
                and click "Open In Browser"
            </Typography>
        </Box>
    )
}