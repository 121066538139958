export const checkIfWebview = () => {



let nav = window.navigator;
const userAgent = nav.userAgent;
const normalizedUserAgent = userAgent.toLowerCase();
//const standalone = window.navigator

const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || (nav.platform === 'MacIntel' && nav.maxTouchPoints > 1);
const isAndroid = /android/.test(normalizedUserAgent);
const isSafari = /safari/.test(normalizedUserAgent);
const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !isSafari);

return isWebview
}