export const ErrorCodes = [
    {
        "code": "auth/too-many-requests",
        "message": "Account temporarily disabled due to too many incorrect attempts. Please try again later or reset your password."
    },
    {
        "code": "auth/wrong-password",
        "message": 'Password is incorrect. Please try again.'
    },
    {
        "code": "auth/user-not-found",
        "message": 'Invalid Email and/or Password'
    },
    {
        "code": "auth/account-exists-with-different-credential",
        "message": "This account is registered to login with a different service."
    },
    {
        "code": "auth/invalid-login-credentials",
        "message": "Incorrect login credentials."
    }
]